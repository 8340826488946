<template>
  <v-btn
    color="primary"
    outlined
    @click="openPopup"
  >
    {{ 'order.action.update_delivery_info'|t }}
  </v-btn>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    formKey: String,
  },
  methods: {
    openPopup() {
      this.$apopup.base({
        width: '650px',
        title: this.$t('order.action.update_delivery_info'),
        bodySlot: () => import('./updateDeliveryInfoPopup.vue'),
        disabledApply: data => {
          if(!data) return true
          return false
        },
        applyOnEnter: false,
        deliveryType: this.deliveryType,
        deliveryInfo: window.eagleLodash.get(this.formData, 'delivery_info'),
        applyCallback: this.updateDeliveryInfo,
      })
    },
    async updateDeliveryInfo(data) {
      this.$store.dispatch(`form/${this.formKey}/setDataColumn`, {
        key: 'delivery_info',
        value: data,
      })
      await this.$nextTick()
      await this.formActions.save()
      await this.formActions.initForm()
    },
  },
  computed: {
    formActions() {
      return this.$store.getters[`form/${this.formKey}/actions`]
    },
    formData() {
      return this.$store.getters[`form/${this.formKey}/data`]
    },
    deliveryType() {
      return window.eagleLodash.get(this.formData, 'delivery.type')
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
